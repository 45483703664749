import { lazy, LazyExoticComponent } from 'react'
import mixpanel from 'mixpanel-browser'

import renderComponent from './helpers/renderHelpers'

mixpanel.init(
  process.env.MIXPANEL_ID as string,
  process.env.ENVIRONMENT === 'production' ? { api_host: process.env.MIXPANEL_HOST } : undefined,
)

type ComponentTypes = 'badge' | 'banner' | 'chip' | 'npsDialog' | 'paymentsButton' | 'paymentsDialog' | 'cardFormButton' | 'cardFormDialog' | 'tagline' | 'cartTagline' | 'lmnDialog' | 'lmnButton' | 'orderSuccess'

const COMPONENTS = {
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  badge: lazy(() => import(/* webpackChunkName: "Badge" */ './components/Badge/Badge')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  banner: lazy(() => import(/* webpackChunkName: "Banner" */'./components/Banner/Banner')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  chip: lazy(() => import(/* webpackChunkName: "Chip" */'./components/Chip/Chip')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  lmnButton: lazy(() => import(/* webpackChunkName: "LmnDialog" */'./components/Button/LmnButton')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  lmnDialog: lazy(() => import(/* webpackChunkName: "LmnDialog" */'./components/Dialogs/Lmn/LmnDialog')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  npsDialog: lazy(() => import(/* webpackChunkName: "NpsDialog" */'./components/Dialogs/Nps/NpsDialog')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  paymentsButton: lazy(() => import(/* webpackChunkName: "PaymentsButton" */'./components/Button/PaymentsButton')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  paymentsDialog: lazy(() => import(/* webpackChunkName: "PaymentsDialog" */'./components/Dialogs/Payments/PaymentsDialog')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  cardFormButton: lazy(() => import(/* webpackChunkName: "cardFormButton" */'./components/Button/OfflineOrderButton')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  cardFormDialog: lazy(() => import(/* webpackChunkName: "cardFormDialog" */'./components/Dialogs/OfflineOrders/OfflineOrderDialog')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  tagline: lazy(() => import(/* webpackChunkName: "Tagline" */'./components/Tagline/Tagline')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  cartTagline: lazy(() => import(/* webpackChunkName: "CartTagline" */'./components/CartTagline/CartTagline')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  thankYou: lazy(() => import(/* webpackChunkName: "ThankYou" */'./components/Dialogs/ThankYou/ThankYouDialog')),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  orderSuccess: lazy(() => import(/* webpackChunkName: "OrderSuccess" */'./components/Dialogs/OrderSuccess/OrderSucess')),
}

const reactRootMap = new Map()

const renderElement = (
  component: ComponentTypes,
  domId: string,
  props?: object,
): void => {
  const Component: LazyExoticComponent<any> = COMPONENTS[component]
  renderComponent(reactRootMap, Component, domId, props)
}

export default {
  renderElement,
}
